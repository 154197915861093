/* global moment */

angular.module(config.app.name).component('vehicle', {
    templateUrl: 'components/procedure/components/vehicle.component.html',
    bindings: {
        vehicle: '<',
        procedureId: '<',
        procedureType: '<',
        canGoBack: '<',
        paid: '<',
        getData: '&',
        setData: '&'
    },
    controller: function ($element, $scope, $timeout, $mdDialog,
        procedureService, $_vehicle, sessionStorageService) {

        var self = this;

        self.view = {
            title: 'Véhicule',
            icon: 'directions_car'
        };

        self.readOnlyMode = self.paid === true;

        let maxDate = new Date();
        maxDate.setDate(maxDate.getDate() - 1);
        maxDate.setHours(0, 0, 0, 0);
        self.minDate = new Date(1900, 1, 1);
        self.maxDate = maxDate;

        self.needFormulaNum = true; // SIV
        self.needRegistrationDate = true; // FNI
        self.residenceDepartment = sessionStorageService.get('residenceDepartment') || null;

        $_vehicle.getVehicleTypes().then((vehicleTypes) => {
            self.vehicleTypes = vehicleTypes;
        });
        $_vehicle.getFuelTypes().then((fuelTypes) => {
            self.fuelTypes = fuelTypes;
        });

        //------------------------------------------------------------------- events ---
        //
        //self.$postLink = function () {};
        // ready - load
        angular.element(function () {
            console.log('>>> the vehicle component is loaded and ready');
            setTimeout(() => {
                //$element[0].dispatchEvent(new CustomEvent('component-load'));
                IMask(document.getElementById('registrationNum'), {
                    mask: ['a[a]-0[00]-a[a]', '0[000] a[aa] *[**]']
                });
                IMask(document.getElementById('formulaNum'), {
                    mask: '0000aa00000'
                });
                IMask(document.getElementById('identificationNum'), {
                    mask: 'ZZZZ[ZZZZZZZZZZZZZ]',
                    definitions: {
                        'Z': /[a-zA-Z0-9]/
                    }
                });
            });
        });

        self.$onChanges = function (changes) {
            if (changes.vehicle) {
                __init(true);
            }
        };

        function __init(fromChanges = false) {
            self.fields = getVehicleFieldsPerProcedure(self.procedureType);
            if (!fromChanges) {
                if (self.fields.has('saleDate') && self.fields.has('saleTime')) {
                    let now = new Date(), minDate = new Date();
                    now.setMilliseconds(0);
                    now.setSeconds(0);
                    minDate.setYear(now.getFullYear() - 10);
                    self.saleDateTime = now;
                    self.saleMinDate = moment(minDate).format('YYYY-MM-DDTHH:mm');
                    self.saleMaxDate = moment(now).format('YYYY-MM-DDTHH:mm');
                    self.getData({
                        type: ['saleDate', 'saleTime'],
                        cb: saleDateTime => {
                            self.saleDateTime = moment(saleDateTime.join(' '), 'DD/MM/YYYY HH:mm').toDate();
                        }
                    });
                }
            }
            if (self.vehicle) {
                self.vehicle = __onDataIn();
            } else {
                self.vehicle = {};
                self.fields.forEach(field => self.vehicle[field] = null);
            }//
            
            self.readOnlyMode = self.paid === true;
        }

        self.$onInit = function () {
            __init();
        };

        self.onSaleDateTimeChange = function () {
            let saleDateTime = moment(self.saleDateTime);
            self.saleDate = saleDateTime.format('DD/MM/YYYY');
            self.saleTime = saleDateTime.format('HH:mm');
            self.setData({
                type: 'saleDateTime',
                data: {
                    saleDate: self.saleDate,
                    saleTime: self.saleTime
                }
            });
        };

        self.validFomulNum = function (input) {
            input.setCustomValidity(isValidFormulaNum(input.value) ? '' : 'format error');
        };

        function isValidFormulaNum(val) {
            return $_vehicle.isValidFormulaNum(val) === true;
        }

        self.onChange = function () {
            console.info('>>> self.vehicle :', self.vehicle);
            if (typeof self.vehicle.registrationNum === 'string') {
                self.setRegNumType($_vehicle.isValidRegNum(self.vehicle.registrationNum, true));
            }
            if (typeof self.vehicle.formulaNum === 'string') {
                self.vehicleForm.formulaNum.$setValidity('formulaNum', isValidFormulaNum(self.vehicle.formulaNum));
            }
            console.info(self.vehicleForm);
            self.vehicleForm.$setSubmitted();
        };
        self.setRegNumType = function (regNumType = null) {
            return;
            if (!regNumType) {
                return;
            }
            if (regNumType === 'fni') {
                self.needRegistrationDate = true;
                self.needFormulaNum = false;
                self.vehicle.formulaNum = null;
            } else {
                self.needRegistrationDate = false;
                self.needFormulaNum = true;
                self.vehicle.registrationDate = null;
            }//
        };

        function __onDataIn() {
            if (typeof self.vehicle === 'string') {
                self.vehicle = JSON.parse(self.vehicle);
            }
            let data = Object.assign({}, self.vehicle);

            for (let i in data) {
                if (i !== 'formulaNum' && !self.fields.has(i)) {
                    delete self.vehicle[i];
                    continue;
                }
                if (['registrationDate', 'firstRegistrationDate'].includes(i)) {
                    if (typeof data[i] === 'string') {
                        let d = moment(data[i], 'DD/MM/YYYY');
                        data[i] = d.isValid() ? d.toDate() : null;
                    }
                }
            }
            //self.vehicle.formulaNum = '1234AB12345';
            data.formulaNum = '1234AB12345';
            return data;
        }
        function __onDataOut() {

            let data = Object.assign({}, self.vehicle);
            for (let i in data) {
                if (data[i] instanceof Date || moment.isMoment(data[i])) {
                    data[i] = moment(data[i]).format('DD/MM/YYYY');
                }
            }
            return data;
        }

        self.showHelpPopup = function (ev, field) {
            $mdDialog.show({
                templateUrl: 'components/procedure/templates/carte.grise.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                /*bindToController: true,*/
                locals: {
                    field: field
                },
                onComplete: function ($scope, element, options) {
                    const fields = {
                        registrationNum: {title: "N° d'immatriculation (A)", width: 114, height: 24, top: 52, left: 8},
                        firstRegistrationDate: {title: 'Date de mise en circulation (B)', width: 130, height: 25, top: 52, left: 158},
                        formulaNum: {title:'N° de formule', width: 100, height: 25, top: 862, left: 8},
                        registrationDate: {title: 'Date de la carte grise (I)', width: 130, height: 25, top: 628, left: 8},
                        fuel: {title: 'Energie (P.3)',width: 66, height: 25, top: 462, left: 236},
                        taxableHorsepower: {title: 'Puissance administrative nationale (P.6) en CV', width: 56, height: 25, top: 462, left: 352},
                        make: {title:'Marque (D.1)', width: 138, height: 25, top: 310, left: 8},
                        model: {title: 'Modèle (D.3)',width: 104, height: 25, top: 368, left: 8},
                        type: {title: 'Genre / type national (J.1)',width: 60, height: 25, top: 425, left: 124},
                        identificationNum: {title: 'VIN (E)', width: 185, height: 25, top: 369, left: 306}
                    };
                    if (fields[options.locals.field]) {
                        setTimeout(() => {
                            var stamp = document.getElementById('stamp');
                            (function(field) {
                                $scope.title = field.title;
                                stamp.style.width = field.width + 'px';
                                stamp.style.height = field.height + 'px';
                                stamp.style.top = field.top + 'px';
                                stamp.style.left = field.left + 'px';
                                stamp.style.display = 'block';
                                stamp.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                                $scope.$apply();
                            })(fields[options.locals.field]);
                        });
                    }
                },
                controller: function ($scope) {
                    $scope.title = null;
                    $scope.close = () => {
                        $mdDialog.hide();
                    };
                }
            }).then(function () {}, function () {});
        };

        self.next = function () {
            let data = {
                vehicle: __onDataOut()
            };

            if (self.fields.has('saleDate') && self.fields.has('saleTime')) {
                data.saleDate = self.saleDate;
                data.saleTime = self.saleTime;
            }

            self.setData({
                type: 'vehicle',
                data: data
            });

        };
        self.getVehicleInfos = function () {
            procedureService.getVehicleInfos(self.vehicle.registrationNum).then((vehicleInfos) => {
                let fieldsToIgnore = new Set(['registrationNum', 'cO2', 'color']);
                if (vehicleInfos) {
                    vehicleInfos.vehicleType = vehicleInfos.vehicleTypeId;
                    delete vehicleInfos.vehicleTypeId;
                    for (let i in vehicleInfos) {
                        let j = i.replace('vehicle', '');
                        j = j.charAt(0).toLowerCase() + j.substr(1);
                        if (!fieldsToIgnore.has(j) && self.fields.has(j)) {
                            switch (j) {
                                case 'registrationDate':
                                case 'firstRegistrationDate':
                                    self.vehicle[j] = moment(vehicleInfos[i], 'DD/MM/YYYY').toDate();
                                    break;
                                default:
                                    self.vehicle[j] = vehicleInfos[i];
                            }
                        }
                    }
                    $timeout(() => {
                        $scope.$apply();
                        self.vehicleForm.$setSubmitted();
                    });
                }
            });
        };

        self.back = function () {
            $element[0].dispatchEvent(new CustomEvent('navigation', {
                detail: 'back'
            }));
        };

    }

});